import { getJson } from '@zapier/toolbox-browser-fetch';

import { UserSession } from '@/types';
import { getGravatarUrl, getUrls } from '@/utils';

export function formatResponseBody(body: any): UserSession {
  return {
    emailHash: body?.email_hash ?? '',
    firstName: body?.first_name ?? '',
    fullName: `${body?.first_name ?? ''} ${body?.last_name ?? ''}`,
    imageUrl: getGravatarUrl(body?.email_hash ?? ''),
    isAuthenticated: body?.is_logged_in ?? false,
    isMasquerade: body?.is_masquerade ?? false,
    isStaff: body?.is_staff ?? false,
    lastName: body?.last_name ?? '',
  };
}

export async function fetchUserSession(): Promise<UserSession> {
  const url = new URL('/api/v4/session/', getUrls().apiBase);

  const responseBody = await getJson(url.href);
  return formatResponseBody(responseBody);
}
