import { APP_BASE_PATH } from '@/constants';

export const getNavLinksLeft = () => {
  return [
    {
      href: `${APP_BASE_PATH}/`,
      isVisible: true,
      label: 'Review queue',
    },
  ];
};
