/** @jsxImportSource @emotion/react */
'use client';

import { ComponentProps, useState } from 'react';

import { css } from '@emotion/react';
import { Colors, Icon, Link, Typography } from '@zapier/design-system';

import { useUserSessionQuery } from '@/hooks';
import { getUrls } from '@/utils';

import AuthButtons from './AuthButtons';
import { MEDIA_MIN_DESKTOP } from './constants';
import LogoZapierPlatform from './LogoZapierPlatform';
import NavLinks from './NavLinks';
import SkipLink from './SkipLink';
import UserDropdown from './UserDropdown';
import { getNavLinksLeft } from './utils';

const Styles = {
  container: css`
    align-items: center;
    background-color: inherit;
    color: inherit;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin: auto;
    position: relative;
    z-index: 1;
  `,
  logo: css`
    display: flex !important;
    left: 50% !important;
    position: absolute !important;
    transform: translateX(-50%) !important;
    ${MEDIA_MIN_DESKTOP} {
      position: static !important;
      transform: none !important;
    },
  `,
  menuButton: (isLightTheme: boolean) => css`
    background-color: transparent;
    border: none;
    color: ${isLightTheme ? 'inherit' : Colors.neutral600};
    cursor: pointer;
    ${MEDIA_MIN_DESKTOP} {
      display: none !important;
    }
  `,
  rightSplit: (isNavOpen: boolean) => css`
    background-color: inherit;
    display: ${isNavOpen ? 'flex' : 'none'};
    flex-direction: column;
    left: -30px;
    position: absolute;
    right: -30px;
    top: 100%;
    ${MEDIA_MIN_DESKTOP} {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex-direction: row;
      flex: 1 0 auto;
      left: auto;
      margin-left: 40px;
      position: relative;
      right: auto;
      top: auto;
    },

  `,
  root: (isLightTheme: boolean) => css`
    ${Typography.base};
    color: ${isLightTheme ? Colors.neutral800 : Colors.neutral100};
    background-color: ${isLightTheme ? Colors.neutral100 : Colors.neutral800};
    height: 68px;
    padding: 0 30px;
  `,
};

type NavLink = Exclude<
  ComponentProps<typeof NavLinks>['linksLeft'],
  undefined
>[0];
type onClickNavLink = ComponentProps<typeof NavLinks>['onClickLink'];

type Props = {
  /** Whether the user is being loaded. */
  isLoadingUser?: boolean;
  /** Links to be used in the left navigation section. */
  navLinks?: NavLink[];
  /** Callback when clicking to log in. */
  onClickLogIn?: () => void;
  /** Callback when clicking to log out. */
  onClickLogOut?: () => void;
  /** Callback when the user clicks on the menu icon button. */
  onClickMenuButton?: () => void;
  /** Callback when the user clicks on a navigation link. */
  onClickNavLink?: onClickNavLink;
  /** Callback when clicking on the Zapier Platform logo. */
  onClickZapierPlatformLogo?: () => void;
  /** Callback when toggling the user dropdown. */
  onToggleUserDropdown?: (isOpen: boolean) => void;
  /** Theme of the header. */
  theme?: 'light' | 'dark';
  /** Anchor to the main content area of the page. The page must contain a node with the id in this prop.  */
  urlSkipLink?: string;
};

const defaultProps = {
  isLoadingUser: false,
  navLinks: undefined,
  onClickLogIn: () => undefined,
  onClickLogOut: () => undefined,
  onClickMenuButton: () => undefined,
  onClickNavLink: () => undefined,
  onClickZapierPlatformLogo: () => undefined,
  onToggleUserDropdown: () => undefined,
  theme: 'dark' as const,
  urlSkipLink: undefined,
};

// This component is isomorphic since it"s consumed by web-ssr at the moment.
export default function Header({
  isLoadingUser = defaultProps.isLoadingUser,
  navLinks = defaultProps.navLinks,
  onClickLogIn = defaultProps.onClickLogIn,
  onClickLogOut = defaultProps.onClickLogOut,
  onClickMenuButton = defaultProps.onClickMenuButton,
  onClickNavLink = defaultProps.onClickNavLink,
  onClickZapierPlatformLogo = defaultProps.onClickZapierPlatformLogo,
  onToggleUserDropdown = defaultProps.onToggleUserDropdown,
  theme = defaultProps.theme,
  urlSkipLink = defaultProps.urlSkipLink,
}: Props) {
  const isLightTheme = theme === 'light';

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);

  const { data: userSession } = useUserSessionQuery();
  const isAuthenticated = userSession?.isAuthenticated ?? false;
  const fullName = userSession?.fullName ?? '';
  const imageUrl = userSession?.imageUrl ?? '';
  const user = isAuthenticated
    ? {
        avatarUrl: imageUrl,
        name: fullName,
      }
    : undefined;

  const urls = getUrls();

  return (
    <header css={Styles.root(isLightTheme)}>
      <div css={Styles.container}>
        <button
          css={Styles.menuButton(isLightTheme)}
          onClick={() => {
            onClickMenuButton();
            setIsNavOpen(!isNavOpen);
          }}
        >
          <Icon name={isNavOpen ? 'x' : 'navMenu'} />
        </button>
        <Link
          aria-label="Zapier Developer Platform"
          css={Styles.logo}
          href={urls.developerPlatform}
          onClick={onClickZapierPlatformLogo}
          tabIndex={0}
        >
          <LogoZapierPlatform
            theme={isLightTheme ? 'light' : 'dark'}
            width={192}
          />
        </Link>
        <SkipLink href={urlSkipLink} text="Skip to main content" />
        <div css={Styles.rightSplit(isNavOpen)}>
          <NavLinks
            isAuthed={!!user}
            isLightTheme={isLightTheme}
            linksLeft={navLinks || getNavLinksLeft()}
            linksRight={navLinks || []}
            onClickLink={onClickNavLink}
          />
        </div>
        {user ? (
          <UserDropdown
            isOpen={isUserDropdownOpen}
            onClickLogOut={onClickLogOut}
            onToggle={() => {
              onToggleUserDropdown(!isUserDropdownOpen);
              setIsUserDropdownOpen(!isUserDropdownOpen);
            }}
            urlLogOut={urls.logOut}
            user={user}
          />
        ) : (
          <AuthButtons
            isLoading={isLoadingUser}
            onClickLogIn={onClickLogIn}
            urlLogIn={urls.logIn}
          />
        )}
      </div>
    </header>
  );
}
